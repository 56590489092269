import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import get from 'lodash.get'
import React from 'react'
import styled from 'styled-components'
import Button from '../components/button'
import HTMLBlock from '../components/html-content'
import Layout from '../components/layout'
import Responsive, { FlexCol, FlexRow } from '../components/responsive'
import Seo from '../components/seo'
import Title from '../components/titles'
import PdfIcon from '../images/icons/pdf.svg'
import colors from '../theme/colors'
const Job = ({ data, pageContext, uri }) => {
    const { parentUid } = pageContext
    const { job_title, job_description, job_description_aside, job_synth, pdf_document_label, pdf_document } = get(
        data,
        'prismicJob.data'
    )
    const jobTitle = get(job_title, 'text')
    const jobDesc = get(job_synth, 'text')
    return (
        <>
            <Seo title={jobTitle} description={jobDesc} />
            <Layout uri={uri} withMiniHeader>
                <Navigation>
                    <Responsive>
                        <Link to={parentUid}>
                            <Button direction="rtl" icon="ArrowLeft">
                                Retour
                            </Button>
                        </Link>
                    </Responsive>
                </Navigation>
                <Section>
                    <Responsive>
                        <JobTitle align="center">
                            <Title as="h1" size="large" bold withDot>
                                {jobTitle}
                            </Title>
                            <OutboundLink href={get(pdf_document, 'url')} target="_blank" rel="noopener noreferrer">
                                <StyledButton
                                    iconComponent={MiniPdfIcon}
                                    direction="rtl"
                                    backgroundColor={colors.beige80}
                                    hoverColor={colors.beige}
                                    textColor={colors.darkBlue}
                                    hoverTextColor={colors.darkBlue}>
                                    {pdf_document_label}
                                </StyledButton>
                            </OutboundLink>
                        </JobTitle>
                        <FlexRow align="flex-start" gutter="4rem" collapseTablet>
                            <FlexCol flex={2}>
                                <HTMLBlock>{job_description.html}</HTMLBlock>
                            </FlexCol>
                            <FlexCol flex={1}>
                                <HTMLBlock>{job_description_aside.html}</HTMLBlock>
                            </FlexCol>
                        </FlexRow>
                    </Responsive>
                </Section>
            </Layout>
        </>
    )
}

const Section = styled.section`
    padding: 4rem 0;
`

const MiniPdfIcon = styled(PdfIcon)`
    width: 1.2rem;
    height: 1.2rem;
`

const StyledButton = styled(Button)`
    ${Title} {
        font-size: 80%;
    }
`

const Navigation = styled.nav`
    background: ${colors.beige50};
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    ${Responsive} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const JobTitle = styled.div`
    align-items: center;
    padding-bottom: 2rem;
    > ${Title} {
        padding: 0;
        padding-bottom: 0.5rem;
    }
`

export const query = graphql`
    query($uid: String!) {
        prismicJob(uid: { eq: $uid }) {
            id
            data {
                job_title {
                    text
                }
                job_description {
                    html
                }
                job_description_aside {
                    html
                }
                job_synth {
                    text
                }
                pdf_document_label
                pdf_document {
                    url
                }
            }
        }
    }
`

export default Job
