import PropTypes from 'prop-types'
import React from 'react'
import * as Icon from 'react-feather'
import styled, { css } from 'styled-components'
import colors from '../theme/colors'
import media from '../theme/media-queries'
import Title from './titles'

const Button = ({ title, icon, children, direction, hoverColor, backgroundColor, iconComponent, ...rest }) => {
    const ButtonIcon = iconComponent || (icon && Icon[icon]) || null
    return (
        <ButtonContainer {...rest}>
            {!!title && (
                <ButtonTitle size="tiny" caps>
                    {title}
                </ButtonTitle>
            )}
            <StyledButton hoverColor={hoverColor} backgroundColor={backgroundColor} direction={direction}>
                <ButtonText>{children}</ButtonText>
                {ButtonIcon && <ButtonIcon size={20} />}
            </StyledButton>
        </ButtonContainer>
    )
}

const ButtonContainer = styled.span`
    transition: all 0.2s ease-in;
    color: ${(props) => props.textColor};
    :hover {
        color: ${(props) => props.hoverTextColor};
    }
`
const ButtonText = styled(Title)`
    white-space: nowrap;
    ${media.phone`
        font-size: smaller;
    `}
`

const StyledButton = styled.span`
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.25rem 0;
    border-radius: 1rem;
    font-weight: 700;
    transition: all 300ms ease;
    text-transform: uppercase;
    direction: ${(props) => props.direction};
    ${(props) => {
        switch (props.direction) {
            case PropValues.direction.rtl:
                return css`
                    & > * {
                        margin-right: 0.8rem;
                        margin-left: 0rem;
                    }
                    & > svg {
                        margin-left: 0.8rem;
                    }
                `
            default:
                return css`
                    & > * {
                        margin-left: 0.8rem;
                        margin-right: 0rem;
                    }
                    & > svg {
                        margin-right: 0.8rem;
                    }
                `
        }
    }}

    background-color: ${(props) => props.backgroundColor};
    ${ButtonContainer}:hover && {
        background-color: ${(props) => props.hoverColor};
    }
`
const ButtonTitle = styled(Title)`
    font-size: 0.625rem;
    line-height: 1.25rem;
    opacity: 0.7;
    padding-left: 0.5rem;
`

const PropValues = {
    direction: {
        ltr: 'ltr',
        rtl: 'rtl',
    },
}

Button.defaultProps = {
    direction: PropValues.direction.ltr,
    hoverColor: colors.vividRed,
    backgroundColor: colors.darkBlue,
    textColor: colors.white,
    hoverTextColor: colors.white,
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(PropValues.direction)),
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    hoverColor: PropTypes.string,
    hoverTextColor: PropTypes.string,
    iconComponent: PropTypes.any,
}

export default Button
